import { useSettings } from '@wix/tpa-settings/react';
import React from 'react';
import { Badge, BadgePriority as BADGE_PRIORITY } from 'wix-ui-tpa/cssVars';
import settingsParams from '../../settingsParams';
import { classes } from './VideoConferenceBadge.st.css';
import CameraSVG from 'wix-ui-icons-common/on-stage/system/VideoFillXSmall';
import { VideoConferenceBadgeDataHooks } from './dataHooks';
import { getContent } from '../../../../utils/content/content';

export interface VideoConferenceBadgeProps {
  videoConferenceProviderId?: string;
  includeConferenceOption?: boolean;
}

const VideoConferenceBadge: React.FC<VideoConferenceBadgeProps> = ({
  videoConferenceProviderId,
  includeConferenceOption,
}) => {
  const settings = useSettings();
  const videoConferenceBadgeVisibility = settings.get(
    settingsParams.videoConferenceBadgeVisibility,
  );

  const shouldShowVideoBadgeText =
    videoConferenceBadgeVisibility &&
    (videoConferenceProviderId || includeConferenceOption);

  const videoConferenceTitle = getContent({
    settings,
    settingsParam: settingsParams.videoConferenceTitle,
  });

  return shouldShowVideoBadgeText ? (
    <div className={classes.root}>
      <Badge
        data-hook={VideoConferenceBadgeDataHooks.VIDEO_CONFERENCE_BADGE}
        priority={BADGE_PRIORITY.light}
        icon={<CameraSVG aria-hidden />}
        className={classes.videoConferenceBadge}
      >
        {videoConferenceTitle}
      </Badge>
    </div>
  ) : null;
};

export default VideoConferenceBadge;
