export enum CouponDataHooks {
  DIVIDER = 'coupon-divider',
  COUPON_TEXT_BUTTON = 'coupon-text-button',
  COUPON_INPUT = 'coupon-input',
  ADD_BUTTON = 'add-coupon-button',
  REMOVE_BUTTON = 'remove-coupon-button',
  ALREADY_USED_COUPON = 'coupon-already-used',
  ACCESSIBILITY_TAG = 'coupon-accessibility-tag',
  SPINNER = 'coupon-spinner',
  WRAPPER = 'coupon-wrapper',
}
