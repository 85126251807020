import { MutableRefObject } from 'react';
import { useTranslation, TFunction } from '@wix/yoshi-flow-editor';
import { SubmissionResponse, FormRef } from '@wix/forms-ui/types';
import { getServiceSlotIdentifier, mapToArray } from '../../../utils';
import { ServiceData, SlotService } from '../../../utils/state/types';
import { PaymentOption } from '../../../types/types';
import { getMaxNumberOfParticipants } from '../../../utils/numberOfParticipant';
import { DynamicPriceInfo } from '../../../types/dynamicPrice';
import { DynamicPriceErrorType } from '../../../types/errors';
import { AddError } from '../Actions/errorHandlers/addError/addError';
import { FormControllerActions } from '../Actions/actions';

export type SubmitForm = {
  actions: FormControllerActions;
  serviceData: ServiceData;
  formRef?: MutableRefObject<FormRef | undefined>;
  withFormValidation?: boolean;
};

export default function useSubmitForm() {
  const { t } = useTranslation();

  const submitForm = ({
    actions,
    serviceData,
    formRef,
    withFormValidation,
  }: SubmitForm) => {
    const { addError } = actions;
    const submissionResponse = formRef?.current?.submit?.();

    if (withFormValidation && submissionResponse) {
      submitFormValidation({
        serviceData,
        submissionResponse,
        addError,
        t,
      });
    }

    return submissionResponse;
  };

  return {
    submitForm,
  };
}

const submitFormValidation = ({
  serviceData,
  submissionResponse,
  addError,
  t,
}: {
  serviceData: ServiceData;
  submissionResponse: SubmissionResponse;
  addError: AddError;
  t: TFunction;
}) => {
  if (!serviceData.isSingleService) {
    return;
  }

  const singleSlotService = mapToArray<SlotService>(
    serviceData.slotServices,
  )[0];

  const { selectedPaymentOption } = singleSlotService;
  const isDynamicPricingCustom =
    !!singleSlotService.dynamicPriceInfo?.customOptions?.length;
  if (singleSlotService.dynamicPriceInfo && isDynamicPricingCustom) {
    submissionResponse!.state.valid =
      submissionResponse?.state.valid! &&
      isDynamicPriceDropdownValid({
        dynamicPriceInfo: singleSlotService.dynamicPriceInfo,
        selectedPaymentOption,
        currentMaxNumberOfParticipants: getMaxNumberOfParticipants(
          serviceData,
          getServiceSlotIdentifier(singleSlotService.nestedSlot),
        ),
        t,
        addError,
      });
  }
};

const isDynamicPriceDropdownValid = ({
  dynamicPriceInfo,
  selectedPaymentOption,
  currentMaxNumberOfParticipants,
  addError,
  t,
}: {
  dynamicPriceInfo: DynamicPriceInfo;
  selectedPaymentOption: PaymentOption;
  currentMaxNumberOfParticipants: number;
  addError: AddError;
  t: TFunction;
}) => {
  const selectedNumberOfParticipants =
    dynamicPriceInfo?.selectedVariants?.reduce(
      (acc, nextVariant) => acc + (nextVariant.numberOfParticipants || 0),
      0,
    ) || 0;

  const isAtLeastOneParticipantSelected = selectedNumberOfParticipants > 0;
  const isEqualToOrLessThanMaxParticipantsAllowed =
    selectedNumberOfParticipants <= currentMaxNumberOfParticipants;

  const isDropdownValid =
    isAtLeastOneParticipantSelected &&
    isEqualToOrLessThanMaxParticipantsAllowed;

  if (!isDropdownValid) {
    if (!isAtLeastOneParticipantSelected) {
      addError(DynamicPriceErrorType.MISSING_REQUIRED_FIELD_DATA, {
        message: t('app.form-inputs.validation-errors.required-field'),
      });
    } else {
      addError(DynamicPriceErrorType.NOT_ENOUGH_SESSIONS_FOR_DYNAMIC_PRICE, {
        message: t(
          'app.booking-details.price-not-enough-sessions-error-message',
          {
            planName: selectedPaymentOption.label,
          },
        ),
      });
    }
  }
  return isAtLeastOneParticipantSelected;
};
