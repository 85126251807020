import { useFedopsLogger } from '@wix/yoshi-flow-editor';

export const useFedopsLoggerAdapter = () => {
  const fedopsLogger = useFedopsLogger();

  const interactionStarted = (interactionName: string, ...args: any[]) => {
    fedopsLogger.interactionStarted(interactionName, ...args);
    fedopsLogger.interactionStarted(`${interactionName}-ecom`, ...args);
  };
  const interactionEnded = (interactionName: string, ...args: any[]) => {
    fedopsLogger.interactionEnded(interactionName, ...args);
    fedopsLogger.interactionEnded(`${interactionName}-ecom`, ...args);
  };
  return {
    interactionStarted,
    interactionEnded,
  };
};
