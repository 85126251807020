import React from 'react';
import { SubmissionResponse } from '@wix/forms-ui/types';
import { FormControllerActions } from '../Actions/actions';

export type FormComponentActions = {
  submitForm: (withFormValidation?: boolean) => SubmissionResponse | undefined;
};

export type FormActions = FormControllerActions & FormComponentActions;

export const FormActionsContext = React.createContext<FormActions>(
  {} as FormActions,
);
export const FormActionsProvider = FormActionsContext.Provider;

export const useFormActions = () => React.useContext(FormActionsContext);
