import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { ButtonAdapter } from '../ButtonAdapter';
import { FormStatus } from '../../../../../types/form-state';
import { ButtonAdapterDataHooks } from '../dataHooks';
import { CartFlow } from '../../../../../types/types';
import { getContent } from '../../../../../utils/content/content';
import settingsParams from '../../../settingsParams';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { SlotServices, SlotService } from '../../../../../utils/state/types';
import { mapToArray } from '../../../../../utils';

export interface BookButtonButtonProps {
  slotServices: SlotServices;
  status: FormStatus;
  cartFlow?: CartFlow;
  primary?: boolean;
}

export const BookButton: React.FC<BookButtonButtonProps> = ({
  slotServices,
  status,
  cartFlow,
  primary,
}) => {
  const { experiments } = useExperiments();
  const settings = useSettings();
  let bookButtonLabel = getContent({
    settings,
    settingsParam: settingsParams.bookNowButtonText,
    experiments,
  });

  const pendingApprovalService = mapToArray<SlotService>(slotServices).find(
    (service) => service.service.isPendingApprovalFlow,
  );

  if (pendingApprovalService) {
    bookButtonLabel =
      settings.get(settingsParams.requestBookingButtonText) ||
      pendingApprovalService.service.actionLabels?.bookingRequestApprovalLabel!;
  }

  return (
    <ButtonAdapter
      withFormValidation
      label={bookButtonLabel}
      dataHook={ButtonAdapterDataHooks.BOOK_NOW_CTA}
      status={status}
      cartFlow={cartFlow}
      primary={primary}
    />
  );
};
