import { DurationMapperOptions } from '@wix/bookings-uou-mappers';
import { DurationFormatter } from '@wix/bookings-date-time';
import { TFunction } from '../../types/types';

export type SlotDuration = {
  durationText?: string;
  durationAriaText?: string;
};

export const getFormattedDuration = ({
  startDate,
  endDate,
  t,
}: {
  startDate?: string;
  endDate?: string;
  t: TFunction;
}): SlotDuration => {
  const durationFormatter = new DurationFormatter(t);
  const durationInMS =
    startDate && endDate
      ? durationFormatter.getDurationInMs(startDate, endDate)
      : '';
  const durationOptions: DurationMapperOptions = {
    hourUnit: 'app.booking-details.duration.units.hours',
    minuteUnit: 'app.booking-details.duration.units.minutes',
  };
  const ariaDurationOptions: DurationMapperOptions = {
    hourUnit: 'app.booking-details.duration.units.aria-hours',
    minuteUnit: 'app.booking-details.duration.units.aria-minutes',
  };
  const durationText = durationInMS
    ? durationFormatter.formatDurationFromMS(durationInMS, durationOptions)
    : '';
  const durationAriaText = durationInMS
    ? durationFormatter.formatDurationFromMS(durationInMS, ariaDurationOptions)
    : '';
  return {
    durationText,
    durationAriaText,
  };
};

export const getCourseTotalNumberOfSessionsDurationText = (
  totalNumberOfSessions: number,
  t: TFunction,
): SlotDuration => {
  const duration = t('app.booking-details.course.duration.text', {
    sessions: totalNumberOfSessions,
  });
  return {
    durationText: duration,
    durationAriaText: duration,
  };
};
