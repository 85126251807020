import React, { useMemo } from 'react';
import { ReservedPaymentOptionIds, TFunction } from '../../../../types/types';
import { getServiceSlotIdentifier, mapToArray } from '../../../../utils';
import { ServiceData, SlotService } from '../../../../utils/state/types';
import { CustomOption } from '../../../../types/dynamicPrice';
import { getMaxNumberOfParticipants } from '../../../../utils/numberOfParticipant';
import { FormStatus } from '../../../../types/form-state';
import { useFormActions } from '../../Hooks/useFormActions';
import PriceOptionDropdown from './PriceOptionDropdown/PriceOptionDropdown';
import { DynamicPriceErrorType, FormError } from '../../../../types/errors';
import { getErrorByType } from '../../../../utils/errors/errors';
import { ClearErrorByTypes } from '../../Actions/errorHandlers/clearErrorByTypes/clearErrorByTypes';
import { OnSelectedVariants } from '../../Actions/onSelectedVariants/onSelectedVariants';
import {
  useExperiments,
  useTranslation,
  Experiments,
} from '@wix/yoshi-flow-editor';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { ExperimentsConsts } from '../../../../consts/experiments';

export interface PriceOptionDropdownContainerProps {
  serviceData: ServiceData;
  status: FormStatus;
  errors: FormError[];
  dateRegionalSettingsLocale: string;
}

export interface PriceOptionNumberOfParticipants {
  choiceId: string;
  title: string;
  numberOfParticipants: number;
}

const PriceOptionDropdownContainer: React.FC<
  PriceOptionDropdownContainerProps
> = ({ serviceData, status, errors, dateRegionalSettingsLocale }) => {
  const { t } = useTranslation();
  const { onSelectedVariants } = useFormActions();
  const { experiments } = useExperiments();
  const actions = useFormActions();

  const dateAndTimeFormatter = useMemo(
    () => new DateTimeFormatter(dateRegionalSettingsLocale),
    [dateRegionalSettingsLocale],
  );

  const { clearErrorByTypes } = actions;
  const firstDynamicPriceError = getErrorByType({
    errorType: DynamicPriceErrorType,
    errors,
  });

  return (
    <>
      {mapToArray<SlotService>(serviceData.slotServices).map((slotService) =>
        renderSlotDynamicPricesDropdown(
          t,
          dateAndTimeFormatter,
          experiments,
          slotService,
          onSelectedVariants,
          clearErrorByTypes,
          serviceData,
          status,
          firstDynamicPriceError,
        ),
      )}
    </>
  );
};

export default PriceOptionDropdownContainer;

export function shouldRenderDynamicPriceDropdown(
  slotService: SlotService,
): boolean {
  return !!(
    slotService.dynamicPriceInfo?.customOptions &&
    !slotService.dynamicPriceInfo?.nonEditable
  );
}

export function renderSlotDynamicPricesDropdown(
  t: TFunction,
  dateAndTimeFormatter: DateTimeFormatter,
  experiments: Experiments,
  slotService: SlotService,
  onSelectedVariants: OnSelectedVariants,
  clearErrorByTypes: ClearErrorByTypes,
  serviceData: ServiceData,
  status: FormStatus,
  firstDynamicPriceError: FormError,
): JSX.Element[] | undefined {
  if (!shouldRenderDynamicPriceDropdown(slotService)) {
    return undefined;
  }

  const getServiceLabel = (
    serviceName: string,
    optionName: string,
    startDate: string,
  ) => {
    if (
      mapToArray<SlotService>(serviceData.slotServices).filter(
        (s) => s.service.name === serviceName,
      ).length === 1
    ) {
      return t('app.booking-details.price-option.dropdown-label', {
        serviceName,
        optionName,
      });
    } else {
      return t('app.booking-details.price-option.dropdown-label-at-time', {
        serviceName,
        optionName,
        serviceTime: dateAndTimeFormatter.formatTime(startDate),
      });
    }
  };

  return slotService.dynamicPriceInfo?.customOptions?.map(
    (customOption: CustomOption) => {
      let label = customOption.name;
      if (experiments.enabled(ExperimentsConsts.FormPaymentOptionNewDropdown)) {
        label = getServiceLabel(
          slotService.service.name,
          customOption.name,
          slotService.nestedSlot.startDate,
        );
      }
      return (
        <PriceOptionDropdown
          key={getServiceSlotIdentifier(slotService.nestedSlot)}
          componentKey={getServiceSlotIdentifier(slotService.nestedSlot)}
          showPrice={
            slotService.selectedPaymentOption.id ===
            ReservedPaymentOptionIds.SingleSession
          }
          options={customOption.options}
          onDropdownClose={(
            priceOptionData: PriceOptionNumberOfParticipants[],
          ) => {
            onSelectedVariants(
              priceOptionData,
              customOption.optionId,
              getServiceSlotIdentifier(slotService.nestedSlot),
            );
            clearErrorByTypes([DynamicPriceErrorType]);
          }}
          maxParticipants={getMaxNumberOfParticipants(
            serviceData,
            getServiceSlotIdentifier(slotService.nestedSlot),
          )}
          disabled={status === FormStatus.PROCESSING_PAYMENT_DETAILS}
          label={label}
          error={!!firstDynamicPriceError}
          errorMessage={firstDynamicPriceError?.params?.message}
        />
      );
    },
  );
}
